import React from "react"
import Layout from "../components/Layout/Layout"
import PreviewBox from "../components/PreviewBox/PreviewBox"
import StyledBackgroundImageFull from "../components/BackgroundImageFull/BackgroundImageFull"
import LayoutStyles from "../components/LayoutStyles/LayoutStyles"
import SectionTransition from "../components/SectionTransition/SectionTransition"
import { graphql, Link } from "gatsby"
import { Helmet } from "react-helmet"
import ScrollNudge from "../components/ScrollNudge/ScrollNudge"

const Blog = ({ data }) => {
  const { edges: posts } = data.allMdx;
  return (
    <div>
      <Layout>
        <Helmet>
          <title>Blog | Alan Henson - Freelance Full-Stack Engineer</title>
        </Helmet>
        <LayoutStyles>
          <StyledBackgroundImageFull imgName={'buildingsAndStreet'}/>
          <div className={'above-fold-container'}>
            <div className={'typography-main'} style={{position: 'relative', top: '15vh'}}>
              Blog
            </div>
            <SectionTransition to={'#BFBAAD'} static>
              <ScrollNudge color={'#000'}/>
            </SectionTransition>
          </div>
          <div style={{backgroundColor: '#BFBAAD', paddingTop: '10vh', paddingBottom: '5vh'}}>
            {posts.map(({ node: post }) => (
              <Link key={post.id} className={'unstyled-link'} to={post.fields.slug}>
                <PreviewBox  title={post.frontmatter.title} subtitle={post.excerpt}/>
              </Link>
            ))}
          </div>
          <SectionTransition from={'#BFBAAD'} to={'#5f6260'} static />
        </LayoutStyles>
      </Layout>
    </div>
  )
}

export const pageQuery = graphql`
    query Blog {
        allMdx(sort: {fields: frontmatter___postOrder, order: DESC}, filter: {frontmatter: {publish: {eq: true}}}) {
            edges {
                node {
                    id
                    excerpt
                    frontmatter {
                        title
                        subtitle
                    }
                    fields {
                        slug
                    }
                }
            }
        }
    }
`

export default Blog
