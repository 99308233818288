import React from "react"
import PreviewBoxStyles from "./PreviewBoxStyles"

const PreviewBox = ({title, subtitle, ...rest}) => {
  return (
    <div>
      <PreviewBoxStyles>
        <div className={'previewBoxTitle'}>{title}</div>
        <div className={'previewBoxSubtitle'}>{subtitle}</div>
      </PreviewBoxStyles>
    </div>
  )
}

export default PreviewBox