import styled from 'styled-components'

const PreviewBoxStyles = styled.footer`
  height: 60vh;
  width: 75vw;
  box-shadow: 0 -30px 15px rgba(95, 98, 96, 0.55);
  border-radius: 3px 3px 0 0;
  margin: 0 auto 10vh auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  background: linear-gradient(
    180deg,
    //rgba(95, 98, 96, 1) 0%, //dark gray
    rgba(196, 196, 196, 1) 0%, //light gray
    rgba(191, 186, 173, 1) 56%
  );
  
  .previewBoxTitle {
    font-size: 2rem;
    margin: 10vh 10vw;
  }
  
  .previewBoxSubtitle {
    font-size: 1.2rem;
    margin: 3vh 3vw;
  }

`

export default PreviewBoxStyles;
